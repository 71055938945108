<template>
  <div>
    <el-card header="数据概览" class="box-card" shadow="never">
      <el-row :gutter="10">
        <el-col :span="6" v-for="i in 8" :key="i">
          <div class="data-card sctp-flex sctp-flex-center sctp-flex-column">
            <div>今日订单金额</div>
            <div>¥100.00</div>
            <div>较昨日-10%</div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'shopPreviewData',
  components: {},
  data () {
    return {}
  }
}
</script>

<style scoped>
  /*@formatter:off*/
  .data-card {padding: 20px;border: 1px solid #eee;margin-bottom: 20px;}
  /*@formatter:on*/
</style>
